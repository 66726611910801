import React from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import PricingTile from './PricingTile';
import './PricingTilesContainer.scss';


const PricingTilesContainer = ({ data, onComparePlans }) => (
  <article className="pricing-container">
    <ContentBox>
      <h1 className="pricing-container__title">
        {data.title}
      </h1>
      <div className="pricing-container__subtitle">
        {data.subtitle}
      </div>
      <div className="pricing-container__tiles row no-gutters">
        { data.tiles.map( (tile, index) => (
            <PricingTile 
              data={tile}
              key={`pricing_tile_${index}`}
              onComparePlans={onComparePlans}
            />
        ))}
      </div>
    </ContentBox>
  </article>
);

export default PricingTilesContainer;
