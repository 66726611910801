import React from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import './ProtectionPlansContainer.scss';
import IconCheckBlue from 'static/images/Check-Blue.svg';
import IconCheckRed from 'static/images/Check-Red.svg';

const colors = ['blue', 'red'];
const icons = [IconCheckBlue, IconCheckRed];

const ProtectionPlansContainer = ({ data }) => {
  const merge = (a, b) => {
    var reduced = a.filter(
      aitem => b.indexOf(aitem) === -1
    );
    return reduced.concat(b);
  };
  let allFeatures = [];
  data.protectionPlans.forEach(plan => {
    allFeatures = merge(allFeatures, plan.featuresList);
  });
  return (
    <article className="aaa-plans">
      <ContentBox>
        <h2 className={`aaa-plans__title`}>{data.title}</h2>
        {data.description && <div
          className={`aaa-plans__description`}
          dangerouslySetInnerHTML={{ __html: data.description }}
        />}
        <div className="aaa-plans__table">
          <div className="aaa-plans__row">
            <div className="aaa-plans__col aaa-plans__feature">
              <div className="aaa-plans__feature-container">
                <div className="aaa-plans__header-content">  
                  <div className="aaa-plans__table-title">
                    {data.tableTitle}
                  </div>
                </div>
                <div className="aaa-plans__hr" />
              </div>
            </div>

            {data.protectionPlans.map((plan, i) => (
              <div key={`plan-${plan.id}`} className="aaa-plans__col aaa-plans__package">
                <div className="aaa-plans__package-container">
                  <div className="aaa-plans__header-content">
                    <div
                      className={`aaa-plans__package-title aaa-plans__package-title-${
                        colors[i % 2]
                      }`}
                    >
                      {plan.title}
                    </div>
                    <div
                      className={`aaa-plans__package-title-short aaa-plans__package-title-${
                        colors[i % 2]
                      }`}
                    >
                      {plan.titleShort}
                    </div>

                    <div className="aaa-plans__package-price-container">
                      <span className="aaa-plans__package-price">
                        {plan.price}
                      </span>
                      <span className="aaa-plans__package-period">
                        /{plan.billingPeriod}
                      </span>
                    </div>
                  </div>

                  <div className="aaa-plans__hr" />
                </div>
              </div>
            ))}
          </div>

          {allFeatures.map((feature, i) => (
            <div key={i} className="aaa-plans__row">
              <div className="aaa-plans__col aaa-plans__feature">
                <div className="aaa-plans__feature-container">
                  <div className="aaa-plans__feature-name">{feature}</div>
                  <div className="aaa-plans__hr" />
                </div>
              </div>
              {data.protectionPlans.map((plan, i) => (
                <div
                  key={plan.id}
                  className="aaa-plans__col aaa-plans__package"
                >
                  <div className="aaa-plans__package-container">
                    <div className="aaa-plans__package-content">
                      {plan.featuresList.find(f => f === feature) && (
                        <img
                          className="aaa-plans__icon-check"
                          src={icons[i % 2]}
                        />
                      )}
                    </div>
                    <div className="aaa-plans__hr" />
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </ContentBox>
    </article>
  );
};

export default ProtectionPlansContainer;
