import React from 'react';
import Img from 'gatsby-image';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import './PricingTile.scss';


const PricingTile = ({ data }) => (
  <section className="pricing-tile col-lg-6 col-12">
    <div className="pricing-tile__padding">
      <div className="pricing-tile__box">  
        <Img
          className="pricing-tile__image"
          alt={data.title}
          fluid={data.image.childImageSharp.fluid}
        />
        <div className="pricing-tile__content">
          <h2 className="pricing-tile__title">{data.title}</h2>
          <h3 className="pricing-tile__subtitle">{data.subtitle}</h3>
          <div 
            className="pricing-tile__description"
            dangerouslySetInnerHTML={{
              __html: data.description
            }}
          />
          <div className="pricing-tile__price">
            <div className="pricing-tile__price__members">
              <div className="pricing-tile__price__title">
                {data.priceMembers.title}
              </div>
              <div className="pricing-tile__price__subtitle">
                {data.priceMembers.subtitle}
              </div>
            </div>
            <div className="pricing-tile__price__delimiter" />
            <div className="pricing-tile__price__nonmembers">
              <div className="pricing-tile__price__title">
                {data.priceNonMembers.title}
              </div>
              <div className="pricing-tile__price__subtitle">
                {data.priceNonMembers.subtitle}
              </div>
            </div>
          </div>
        </div>
        <SeparatorWithGradient className="pricing-tile__separator" />
      </div>
    </div>
  </section>
);

export default PricingTile;
