import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import CTAForm from 'components/CTAForm';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import PricingTiles from 'components/PricingTiles';
import ProtectionPlansContainer from 'components/ProtectionPlansContainer/ProtectionPlansContainer';


const PricingPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO meta={data.pricingYaml.metaTags} title={data.pricingYaml.title} />  
      <PricingTiles data={data.pricingYaml.pricingTiles} />
      <ProtectionPlansContainer data={{ ...data.pricingYaml.protectionPlans, protectionPlans: data.pricingYaml.plans }} />
      <CTAForm />
      <ZipGateContainer location={location} />
    </Layout>
  );
};

export default PricingPage;

export const pageQuery = graphql`
  query PricingPageQuery {
    pricingYaml {
      id
      title
      metaTags {
        name
        content
      }
      plans {
        id
        title
        titleShort
        subtitle
        price
        billingPeriod
        featuresList
      }
      protectionPlans {
        title
        description
        tableTitle
      }
      separator {
        title
        label
        callToActionButton {
          text
          url
        }
      }
      pricingTiles {
        title
        subtitle
        tiles {
          title
          subtitle
          description
          priceMembers {
            title
            subtitle
          }
          priceNonMembers {
            title
            subtitle
          }
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
